var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "pdv-container" },
    [
      _c("header", { staticClass: "header px-1" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex column align-items-center justify-content-between",
          },
          [
            _c("div", { staticClass: "d-flex column align-items-center" }, [
              _c(
                "span",
                { staticClass: "brand-logo mr-1" },
                [
                  _c("b-img", {
                    staticClass: "rounded",
                    attrs: {
                      id: "logo",
                      src: _vm.style ? _vm.style.reducedHeaderImage : "",
                      alt: "logo",
                      fluid: "",
                    },
                  }),
                ],
                1
              ),
              _c("h1", { staticClass: "text-dark h2 mb-0" }, [
                _vm._v(" " + _vm._s(_vm.getPageTitle) + " "),
              ]),
            ]),
            _vm.isDevelopment
              ? _c(
                  "div",
                  { staticClass: "d-flex column align-items-center" },
                  [
                    _c("span", [_vm._v("DEV (use-paybox-id) ")]),
                    _c("e-paybox-combo", {
                      staticStyle: { "min-width": "350px" },
                      attrs: {
                        id: "filter-payboxes",
                        value: _vm.currentPayboxConfiguration.id
                          ? _vm.currentPayboxConfiguration.id.toString()
                          : null,
                        required: false,
                        "show-label": false,
                        placeholder: _vm.$t("Config Mac Address"),
                      },
                      on: { input: _vm.onUsePaybox },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-navbar-nav",
              { staticClass: "pr-1" },
              [
                _c("chat-tool", { staticClass: "chat-tool-nav-paybox" }),
                _c("user-dropdown"),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "content-pdv" }, [_c("router-view")], 1),
      _c(
        "aside",
        {
          staticClass: "aside",
          class: { "aside-show": !_vm.hideAside },
          attrs: { id: "paybox_options" },
          on: {
            click: function ($event) {
              _vm.hideAside = false
            },
            mouseenter: function ($event) {
              return _vm.onClearAsideTimer()
            },
            mouseleave: function ($event) {
              return _vm.onHideAside(true)
            },
          },
        },
        [
          _c("pay-box-aside", {
            attrs: { "hide-aside": _vm.hideAside },
            on: { "show-sidebar": _vm.onShowSidebar },
          }),
        ],
        1
      ),
      _c("pay-box-sidebar", { ref: "sidebarHelper" }),
      _c("modal-tef-messages"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }